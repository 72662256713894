/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                //NAVIGATION STUFFS
                $(document).ready(function () {
                    $('.icon-one').click(function () {
                        $('.icon-one').toggleClass('active-one');
                        $('#sidebar').toggleClass('active');
                    });
                });

                //Smooth Scrolling
                $(function () {
                    // This will select everything with the class smoothScroll
                    // This should prevent problems with carousel, scrollspy, etc...
                    $('.kontakt a').click(function () {
                        $('#sidebar').toggleClass('active');
                        $('.icon-one').toggleClass('active-one');
                        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                            if (target.length) {
                                $('html,body').stop().animate({
                                    scrollTop: target.offset().top - 100
                                }, 1000); // The number here represents the speed of the scroll in milliseconds
                                //console.log(target.offset().top);
                                return false;
                            }
                        }

                    });

                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                //VIDEO VIMEO PLUGIN
                var timeoutId;
                var $videoBgAspect = $(".videobg-aspect");
                var $videoBgWidth = $(".videobg-width");
                var videoAspect = $videoBgAspect.outerHeight() / $videoBgAspect.outerWidth();

                function videobgEnlarge() {
                    windowAspect = ($(window).height() / $(window).width());
                    if (windowAspect > videoAspect) {
                        $videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
                    } else {
                        $videoBgWidth.width(100 + "%");
                    }
                }

                $(window).resize(function () {
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(videobgEnlarge, 100);
                });

                $(function () {
                    videobgEnlarge();
                });
                var iframe = document.getElementById('header-video');
                var player = $f(iframe);
                var playButton = document.getElementById("play-button");
                playButton.addEventListener("click", function () {
                    player.api("play");
                });

                //Milestone & Team Carousel Code

                if ($(window).width() > 575) {
                    $(".milestones-mobile-wrap").remove();
                    $('#milestones-pulser .carousel-item').each(function () {
                        var viewportWidth = $(window).width();
                        if (viewportWidth > 1199) {
                            numberOfCarouselItems = 10;
                            $('.hide-controls').css('display', 'none');
                        }
                        if (viewportWidth < 1199 && viewportWidth > 767) {
                            numberOfCarouselItems = 4;
                        }
                        if (viewportWidth < 768 && viewportWidth > 575) {
                            numberOfCarouselItems = 1;
                        }

                        var next = $(this).next();
                        if (!next.length) {
                            next = $(this).siblings(':first');
                        }
                        next.children(':first-child').clone().appendTo($(this));

                        for (var i = 0; i < numberOfCarouselItems; i++) {
                            next = next.next();
                            if (!next.length) {
                                next = $(this).siblings(':first');
                            }
                            next.children(':first-child').clone().appendTo($(this));
                        }
                    });
                    //Team Carousel Code
                    $('#team-slider .carousel-item').each(function () {
                        var viewportWidth = $(window).width();
                        if (viewportWidth > 767) {
                            numberOfCarouselItems = 2;
                        }
                        if (viewportWidth < 768 && viewportWidth > 575) {
                            numberOfCarouselItems = 0;
                        }

                        var next = $(this).next();
                        if (!next.length) {
                            next = $(this).siblings(':first');
                        }
                        next.children(':first-child').clone().appendTo($(this));

                        for (var i = 0; i < numberOfCarouselItems; i++) {
                            next = next.next();
                            if (!next.length) {
                                next = $(this).siblings(':first');
                            }
                            next.children(':first-child').clone().appendTo($(this));
                        }
                    });
                }
                else {
                    $(".milestones-pulser-wrap").remove();
                }



                ////////Sliding Effects and Headline Height Calculations

                vh = $(window).height() * 80 / 100;
                vh75 = $(window).height() * 75 / 100;
                vh1 = $(window).height() * -15 / 100;
                vh2 = $(window).height() * 55 / 100;
                vh3 = $(window).height() * 65 / 100;
                vh4 = $(window).height() * 70 / 100;
                vhtest = $(window).height() * -32.5 / 100;

                $(function () {
                    var CurrentScroll = 0;


                    $(window).scroll(function (event) {

                        var NextScroll = $(this).scrollTop();
                        headline_1_distance = $('.headline-1').offset().top - $(window).scrollTop();
                        headline_2_distance = $('.headline-2').offset().top - $(window).scrollTop();
                        headline_3_distance = $('.headline-3').offset().top - $(window).scrollTop();
                        headline_facts_distance = $('.hardfacts-headline').offset().top - $(window).scrollTop();
                        slogan01_text_distance = $('.slogan-1-text').offset().top - $(window).scrollTop();
                        slogan02_text_distance = $('.slogan-2-text').offset().top - $(window).scrollTop();
                        slogan03_text_distance = $('.slogan-3-text').offset().top - $(window).scrollTop();
                        slogan04_text_distance = $('.slogan-4-text').offset().top - $(window).scrollTop();

                        if (NextScroll > CurrentScroll) {
                            //write the codes related to down-ward scrolling here
                            //console.log(headline_facts_distance);
                            if (headline_1_distance < vh) {
                                $('.running-text-1').show();
                            }
                            if (headline_2_distance < vh) {
                                $('.running-text-2').show();
                                $('.running-text-1').hide();
                                $('.player-control').hide();
                                $('.effect1').css('transform', 'translate(0,-100vh)');
                                $('.img1').css('transform', 'translate(0,100vh)');
                                $('.effect2').css('transform', 'scale(1.2)');
                            }
                            if (headline_3_distance < vh) {
                                $('.running-text-3').show();
                                $('.running-text-2').hide();
                                $('.effect2').css('transform', 'translate(0,-100vh)');
                                $('.img2').css('transform', 'translate(0,100vh)');
                                $('.effect3').css('transform', 'scale(1.2)');
                            }
                            if (headline_3_distance < vhtest) {
                                $('.running-text-3').css({"position": "relative", "top": "95vh"});

                            }
                            if (headline_facts_distance < vh2) {
                                $('.facts-box1').css('transform', 'translateY(0vw)');
                                $('.facts-box-inside1').css('transform', 'translateY(0vw)');
                            }
                            if (headline_facts_distance < vh3) {
                                $('.facts-box2').css('transform', 'translateY(0vw)');
                                $('.facts-box-inside2').css('transform', 'translateY(0vw)');
                            }

                            if (slogan01_text_distance < vh4) {
                                $('.slogan-1-wrapper').css('background-position', '0px 0px');
                            }
                            if (slogan02_text_distance < vh4) {
                                $('.slogan-2-wrapper').css('background-position', '0px 0px');
                            }
                            if (slogan03_text_distance < vh4) {
                                $('.slogan-3-wrapper').css('background-position', '0px 0px');
                            }
                            if (slogan04_text_distance < vh4) {
                                $('.slogan-4-wrapper').css('background-position', '0px 0px');
                            }


                        }
                        else {
                            //write the codes related to upward-scrolling here

                            if (headline_3_distance > vh) {
                                $('.running-text-3').hide();
                                $('.running-text-2').show();
                                $('.effect2').css('transform', 'translate(0,0)');
                                $('.img2').css('transform', 'translate(0,0)');
                                $('.effect3').css('transform', 'scale(1)');
                            }
                            if (headline_3_distance > vhtest) {
                                if ($(window).width() < 575) {
                                    $('.running-text-3').css({"position": "fixed", "top": "60vh"});

                                } else {
                                    $('.running-text-3').css({"position": "fixed", "top": "72vh"});
                                }
                            }
                            if (headline_2_distance > vh) {
                                $('.running-text-2').hide();
                                $('.running-text-1').show();
                                $('.player-control').show();
                                $('.effect1').css('transform', 'translate(0,0)');
                                $('.img1').css('transform', 'translate(0,0)');
                                $('.effect2').css('transform', 'scale(1)');
                            }
                            if (headline_facts_distance > vh2) {
                                $('.facts-box1').css('transform', 'translateY(40vw)');
                                $('.facts-box-inside1').css('transform', 'translateY(-40vw)');
                            }
                            if (headline_facts_distance > vh3) {
                                $('.facts-box2').css('transform', 'translateY(40vw)');
                                $('.facts-box-inside2').css('transform', 'translateY(-40vw)');
                            }
                            if ($(window).width() > 767 ) {
                                if (slogan01_text_distance > vh4) {
                                    $('.slogan-1-wrapper').css('background-position', '0px 20vw');
                                }
                                if (slogan02_text_distance > vh4) {
                                    $('.slogan-2-wrapper').css('background-position', '0px 20vw');
                                }
                                if (slogan03_text_distance > vh4) {
                                    $('.slogan-3-wrapper').css('background-position', '0px 20vw');
                                }
                                if (slogan04_text_distance > vh4) {
                                    $('.slogan-4-wrapper').css('background-position', '0px 20vw');
                                }
                            }else{
                                if (slogan01_text_distance > vh4) {
                                    $('.slogan-1-wrapper').css('background-position', '0px 35vw');
                                }
                                if (slogan02_text_distance > vh4) {
                                    $('.slogan-2-wrapper').css('background-position', '0px 35vw');
                                }
                                if (slogan03_text_distance > vh4) {
                                    $('.slogan-3-wrapper').css('background-position', '0px 35vw');
                                }
                                if (slogan04_text_distance > vh4) {
                                    $('.slogan-4-wrapper').css('background-position', '0px 35vw');
                                }
                            }


                        }

                        CurrentScroll = NextScroll;  //Updates current scroll position
                    });
                });

                //Swipe Slides on Mobile when there is no Arrow Navigation
                $("#team .carousel").on("touchstart", function (event) {
                    var xClick = event.originalEvent.touches[0].pageX;
                    $(this).one("touchmove", function (event) {
                        var xMove = event.originalEvent.touches[0].pageX;
                        if (Math.floor(xClick - xMove) > 5) {
                            $(this).carousel('next');
                        }
                        else if (Math.floor(xClick - xMove) < -5) {
                            $(this).carousel('prev');
                        }
                    });
                    $(".carousel").on("touchend", function () {
                        $(this).off("touchmove");
                    });
                });

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Leistungen page, note the change from about-us to about_us.
        'services': {
            init: function () {
                // JavaScript to be fired on the about us page

                //Mobile Accordion Height Calculation

                if ($(window).width() < 767) {
                    $(".lackierstrassen-details").each(function () {
                        var assignedHeight = $(this).outerHeight();
                        $(this).siblings().mouseenter(function () {
                            $(this).css('margin-bottom', assignedHeight + 50);
                        });
                        $(this).siblings().mouseleave(function () {
                            $(this).css('margin-bottom', '0');
                        });
                    });

                }


                //VIDEO VIMEO PLUGIN
                var timeoutId;
                var $videoBgAspect = $(".videobg-aspect");
                var $videoBgWidth = $(".videobg-width");
                var videoAspect = $videoBgAspect.outerHeight() / $videoBgAspect.outerWidth();

                function videobgEnlarge() {
                    windowAspect = ($(window).height() / $(window).width());
                    if (windowAspect > videoAspect) {
                        $videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
                    } else {
                        $videoBgWidth.width(100 + "%");
                    }
                }

                $(window).resize(function () {
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(videobgEnlarge, 100);
                });
                $(function () {
                    videobgEnlarge();
                });
                var iframe = document.getElementById('header-video');
                var player = $f(iframe);
                var playButton = document.getElementById("play-button");
                playButton.addEventListener("click", function () {
                    player.api("play");
                });

                ////////Sliding Effects and Headline Height Calculations

                vh = $(window).height() * 80 / 100;
                vh5 = $(window).height() * -85 / 100;
                vhtest = $(window).height() * -32.5 / 100;

                $(function () {
                    var CurrentScroll = 0;


                    $(window).scroll(function (event) {

                        var NextScroll = $(this).scrollTop();
                        headline_1_distance = $('.headline-1').offset().top - $(window).scrollTop();
                        headline_2_distance = $('.headline-2').offset().top - $(window).scrollTop();
                        headline_3_distance = $('.headline-3').offset().top - $(window).scrollTop();
                        headline_4_distance = $('.headline-4').offset().top - $(window).scrollTop();
                        if (NextScroll > CurrentScroll) {
                            //write the codes related to down-ward scrolling here
                            //console.log(headline_facts_distance);
                            if (headline_1_distance < vh) {
                                $('.running-text-1').show();
                            }
                            if (headline_2_distance < vh) {
                                $('.running-text-2').show();
                                $('.running-text-1').hide();
                                $('.player-control').hide();
                                $('.effect1').css('transform', 'translate(0,-100vh)');
                                $('.img1').css('transform', 'translate(0,100vh)');
                                //$('.effect2').css('transform', 'scale(1.2)');
                            }
                            if (headline_3_distance < vh) {
                                $('.running-text-3').show();
                                $('.running-text-2').hide();
                                $('.effect2').css('transform', 'translate(0,-100vh)');
                                $('.img2').css('transform', 'translate(0,100vh)');
                                //$('.effect3').css('transform', 'scale(1.2)');
                            }

                            if (headline_4_distance < vh) {
                                $('.running-text-4').show();
                                $('.running-text-3').hide();
                                $('.effect3').css('transform', 'translate(0,-100vh)');
                                $('.img3').css('transform', 'translate(0,100vh)');
                                //$('.effect4').css('transform', 'scale(1.2)');
                            }
                            if (headline_4_distance < vh5) {
                                $('.effect4').hide();
                                $('.overlay-bgc').hide();
                                $('.bgi-lackierstrassen').show();
                            }

                        }
                        else {
                            //write the codes related to upward-scrolling here
                            if (headline_4_distance > vh) {
                                $('.running-text-4').hide();
                                $('.running-text-3').show();
                                $('.effect3').css('transform', 'translate(0,0)');
                                $('.img3').css('transform', 'translate(0,0)');
                                //$('.effect4').css('transform', 'scale(1)');
                            }
                            if (headline_3_distance > vh) {
                                $('.running-text-3').hide();
                                $('.running-text-2').show();
                                $('.effect2').css('transform', 'translate(0,0)');
                                $('.img2').css('transform', 'translate(0,0)');
                                //$('.effect3').css('transform', 'scale(1)');
                            }
                            if (headline_2_distance > vh) {
                                $('.running-text-2').hide();
                                $('.running-text-1').show();
                                $('.player-control').show();
                                $('.effect1').css('transform', 'translate(0,0)');
                                $('.img1').css('transform', 'translate(0,0)');
                                //$('.effect2').css('transform', 'scale(1)');
                            }
                            if (headline_4_distance > vh5) {
                                $('.effect4').show();
                                $('.overlay-bgc').show();
                                $('.bgi-lackierstrassen').hide();
                            }

                        }

                        CurrentScroll = NextScroll;  //Updates current scroll position
                    });
                });

                //Milestone & Team Carousel Code

                if ($(window).width() > 575) {
                    $(".milestones-mobile-wrap").remove();
                    $('#process-pulser .carousel-item').each(function () {
                        var viewportWidth = $(window).width();
                        if (viewportWidth > 1199) {
                            numberOfCarouselItems = 10;
                            $('.hide-controls').css('display', 'none');
                        }
                        if (viewportWidth < 1199 && viewportWidth > 767) {
                            numberOfCarouselItems = 4;
                        }
                        if (viewportWidth < 768 && viewportWidth > 575) {
                            numberOfCarouselItems = 1;
                        }

                        var next = $(this).next();
                        if (!next.length) {
                            next = $(this).siblings(':first');
                        }
                        next.children(':first-child').clone().appendTo($(this));

                        for (var i = 0; i < numberOfCarouselItems; i++) {
                            next = next.next();
                            if (!next.length) {
                                next = $(this).siblings(':first');
                            }
                            next.children(':first-child').clone().appendTo($(this));
                        }
                    });
                }
                else {
                    $(".milestones-pulser-wrap").remove();
                }

               /* $(window).resize(function () {
                    if ($(window).width() > 768) {
                        location.reload();
                    }
                });*/

            }
        },
        //Referenzen Page
        'references': {
            init: function () {

                //VIDEO VIMEO PLUGIN
                var timeoutId;
                var $videoBgAspect = $(".videobg-aspect");
                var $videoBgWidth = $(".videobg-width");
                var videoAspect = $videoBgAspect.outerHeight() / $videoBgAspect.outerWidth();

                function videobgEnlarge() {
                    windowAspect = ($(window).height() / $(window).width());
                    if (windowAspect > videoAspect) {
                        $videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
                    } else {
                        $videoBgWidth.width(100 + "%");
                    }
                }

                $(window).resize(function () {
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(videobgEnlarge, 100);
                });

                $(function () {
                    videobgEnlarge();
                });
                var iframe = document.getElementById('header-video');
                var player = $f(iframe);
                var playButton = document.getElementById("play-button");
                playButton.addEventListener("click", function () {
                    player.api("play");
                });

                vh3 = $(window).height() * 2 / 100;
                function checkVideoHeight() {
                    if ($(window).width() > 767) {
                        var explanation_height = $(".video-outside video").height();
                        $('.explanation').css('height', explanation_height);
                    }
                }
                $( document ).ready(function() {
                    checkVideoHeight();
                });
                $(window).resize(checkVideoHeight);
                $(function () {
                    var CurrentScroll = 0;
                    $(window).scroll(function (event) {

                        section_1_distance = $('.section-1').offset().top - $(window).scrollTop();
                        section_2_distance = $('.section-2').offset().top - $(window).scrollTop();
                        section_3_distance = $('.section-3').offset().top - $(window).scrollTop();
                        section_4_distance = $('.section-4').offset().top - $(window).scrollTop();
                        NextScroll = $(this).scrollTop();

                        if (NextScroll > CurrentScroll) {
                            //write the codes related to down-ward scrolling here


                            if (section_1_distance < vh3) {
                                checkVideoHeight();
                                $('.left-1').css('transform', 'translateX(0)');
                                $('.left-1-outside').css('transform', 'translateX(0)');
                                $('.right-1').css('transform', 'translateX(0)');
                                $('.right-1-outside').css('transform', 'translateX(0)');
                                $('.top-1').css('transform', 'translateY(0)');
                                $('.top-1-outside').css('transform', 'translateY(0)');
                                $('.bottom-1').css('transform', 'translateY(0)');
                                $('.bottom-1-outside').css('transform', 'translateY(0)');
                            }
                            if (section_2_distance < vh3) {
                                checkVideoHeight();
                                $('.left-2').css('transform', 'translateX(0)');
                                $('.left-2-outside').css('transform', 'translateX(0)');
                                $('.right-2').css('transform', 'translateX(0)');
                                $('.right-2-outside').css('transform', 'translateX(0)');
                                $('.top-2').css('transform', 'translateY(0)');
                                $('.top-2-outside').css('transform', 'translateY(0)');
                                $('.bottom-2').css('transform', 'translateY(0)');
                                $('.bottom-2-outside').css('transform', 'translateY(0)');
                            }
                            if (section_3_distance < vh3) {
                                checkVideoHeight();
                                $('.left-3').css('transform', 'translateX(0)');
                                $('.left-3-outside').css('transform', 'translateX(0)');
                                $('.right-3').css('transform', 'translateX(0)');
                                $('.right-3-outside').css('transform', 'translateX(0)');
                                $('.top-3').css('transform', 'translateY(0)');
                                $('.top-3-outside').css('transform', 'translateY(0)');
                                $('.bottom-3').css('transform', 'translateY(0)');
                                $('.bottom-3-outside').css('transform', 'translateY(0)');
                            }
                            if (section_4_distance < vh3) {
                                checkVideoHeight();
                                $('.left-4').css('transform', 'translateX(0)');
                                $('.left-4-outside').css('transform', 'translateX(0)');
                                $('.right-4').css('transform', 'translateX(0)');
                                $('.right-4-outside').css('transform', 'translateX(0)');
                                $('.top-4').css('transform', 'translateY(0)');
                                $('.top-4-outside').css('transform', 'translateY(0)');
                                $('.bottom-4').css('transform', 'translateY(0)');
                                $('.bottom-4-outside').css('transform', 'translateY(0)');
                            }

                        } else {
                            //write the codes related to upward-scrolling here
                            if (section_1_distance > vh3) {
                                $('.left-1').css('transform', 'translateX(100%)');
                                $('.left-1-outside').css('transform', 'translateX(-100%)');
                                $('.right-1').css('transform', 'translateX(-100%)');
                                $('.right-1-outside').css('transform', 'translateX(100%)');
                                $('.top-1').css('transform', 'translateY(100%)');
                                $('.top-1-outside').css('transform', 'translateY(-100%)');
                                $('.bottom-1').css('transform', 'translateY(-100%)');
                                $('.bottom-1-outside').css('transform', 'translateY(100%)');
                            }
                            if (section_2_distance > vh3) {
                                $('.left-2').css('transform', 'translateX(100%)');
                                $('.left-2-outside').css('transform', 'translateX(-100%)');
                                $('.right-2').css('transform', 'translateX(-100%)');
                                $('.right-2-outside').css('transform', 'translateX(100%)');
                                $('.top-2').css('transform', 'translateY(100%)');
                                $('.top-2-outside').css('transform', 'translateY(-100%)');
                                $('.bottom-2').css('transform', 'translateY(-100%)');
                                $('.bottom-2-outside').css('transform', 'translateY(100%)');
                            }
                            if (section_3_distance > vh3) {
                                $('.left-3').css('transform', 'translateX(100%)');
                                $('.left-3-outside').css('transform', 'translateX(-100%)');
                                $('.right-3').css('transform', 'translateX(-100%)');
                                $('.right-3-outside').css('transform', 'translateX(100%)');
                                $('.top-3').css('transform', 'translateY(100%)');
                                $('.top-3-outside').css('transform', 'translateY(-100%)');
                                $('.bottom-3').css('transform', 'translateY(-100%)');
                                $('.bottom-3-outside').css('transform', 'translateY(100%)');
                            }
                            if (section_4_distance > vh3) {
                                $('.left-4').css('transform', 'translateX(100%)');
                                $('.left-4-outside').css('transform', 'translateX(-100%)');
                                $('.right-4').css('transform', 'translateX(-100%)');
                                $('.right-4-outside').css('transform', 'translateX(100%)');
                                $('.top-4').css('transform', 'translateY(100%)');
                                $('.top-4-outside').css('transform', 'translateY(-100%)');
                                $('.bottom-4').css('transform', 'translateY(-100%)');
                                $('.bottom-4-outside').css('transform', 'translateY(100%)');
                            }
                        }

                        CurrentScroll = NextScroll; //Updates current scroll position
                    });
                });

            }
        }
    };


    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.
